import Swiper from 'swiper';
import AOS from 'aos';

import '@css/main.scss';
import { Header } from '../util/header';
import { Slider } from '../scripts/Slider';
import { Archive } from '../scripts/Archive';
import { AnimatedCounters } from '../scripts/AnimatedCounter';
import { Header as _Header } from '../scripts/Header';
import {Autoplay, Navigation, Pagination} from "swiper/modules";

export default {
    init() {
        window.device = this.initDevice();

        new Header()
        new Slider(Swiper, [Navigation, Pagination, Autoplay])
        new Archive()
        new AnimatedCounters()
        new _Header()
        // new Parallax()

        AOS.init()
        AOS.refresh()

    },
    initDevice() {
        let userAgent = window.navigator.userAgent.toLowerCase();
        let isMobile = /iPhone|Android/i.test(userAgent);
        let isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        return (isMobile || isTablet) ? 'mobile' : 'desktop';
    },
    finalize() {

    },
};
