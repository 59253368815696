import gsap from 'gsap'
import $ from 'jquery'

export class Header {
    constructor()
    {

        this.banner = document.querySelector('header.banner')
        window.addEventListener('scroll', this.colormenu());

        this.search()
        this.tl = gsap.timeline({paused:true});
        this.animation()

        const burger = document.querySelector('#burger')
        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                if (this.banner.classList.contains('expanded')) {
                    this.tl.play()
                } else {
                    this.tl.reverse()
                }
            })
        }
    }

    animation()
    {
        const nav = this.banner.querySelector('.nav-primary.mobile')
        this.tl.to(nav, {x: 0, duration: 0.1})

    }

    search()
    {
      jQuery('.search-icon').click(function(){
        jQuery('#search-modal').fadeIn();
      });

      jQuery('#close-search').click(function(){
        jQuery('#search-modal').fadeOut();
      });

    }

    colormenu()
    {
      let c = $('body .banner');
      let b = $(window).scrollTop();

      if (b > 0) {
        c.addClass('scrolled');
      }

      if (b == 0) {
        c.removeClass('scrolled');
      }
    }

}
