import $ from 'jquery'

export class Header {

    constructor()
    {
        this.init()
    }

    init()
    {
        this.registerElements()
        if (window.device === 'desktop') {
            this.registerIntersectionObserver()
        }
      //this.initInitialColor()
        this.scrollHandler()
        this.resetAnchor()
        this.anchorHandler()
        this.startAnimation()

    }

    registerIntersectionObserver()
    {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (this.firstTriggers !== 0) {
                    this.firstTriggers -= 1
                    return;
                }

                let section = entry.target
                let sectionColor = window.getComputedStyle(section, null).getPropertyValue('background-color'); // get background color of section
                this.switchHeaderColors(sectionColor)

            })
        }, this.options)

        this.elements.forEach((element) => {
            observer.observe(element);
        })
    }

    registerElements()
    {
        this.colors = {
            'default': '',
            'rgb(255, 255, 255)': 'dark-header',
            'rgb(1, 21, 56)': 'light-header',

        }

        this.header = document.querySelector('header')
        this.elements = document.querySelectorAll('section')
        this.firstTriggers = this.elements.length

        let pageHeight = window.innerHeight;
        let headerHeight = this.header.offsetHeight;

        this.options = {
            root: document.body,
            rootMargin: `${headerHeight * -1}px 0px ${(pageHeight * -1) + headerHeight}px 0px`,
            threshold: 0,
        }

    }

    switchHeaderColors(color)
    {
        console.log(color, Object.keys(this.colors))
        let _color = (Object.keys(this.colors).includes(color)) ? this.colors[color] : this.colors['default']
        Object.values(this.colors).forEach((value) => {
            if (value !== '') {
                this.header.classList.remove(value)
            }
        })
        if (_color !== '') {
            this.header.classList.add(_color)
        }
    }

    initInitialColor()
    {
        let firstSection = this.elements[0]
        let sectionColor = window.getComputedStyle(firstSection, null).getPropertyValue('background-color');
        this.switchHeaderColors(sectionColor)

    }

    scrollHandler()
    {
        if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
            this.header.classList.add('scrolled')
        }

        document.addEventListener('scroll', () => {
            let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

            if (height > 0 && !this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
            if (height === 0 && this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
        })

    }

    anchorHandler()
    {
        $('a[href*="#"]:not([href="#"])').click(function () {
            var target = $(this.hash);
            $('html,body').stop().animate({
                scrollTop: target.offset().top - 220,
            }, 'linear');
        });
        if (location.hash) {
            var id = $(location.hash);
        }
        $(window).on('load', function () {
            if (location.hash) {
                $('html,body').animate({scrollTop: id.offset().top - 220}, 'linear')
            }
        });
    }

    resetAnchor()
    {
        if ((window.location.href.indexOf('nos-vins') > -1) || (window.location.href.indexOf('our-wines') > -1)) {
            $('#menu-item-18 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });

            $('#menu-item-286 .sub-menu a').each(function () {

                  let hash = $(this).attr('href').split('#');
                  $(this).attr('href', '#' + hash[1]);

            });
        }
        if ($('body').hasClass('page-id-10') || $('body').hasClass('page-id-253')) {
            $('#menu-item-22 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });

            $('#menu-item-287 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });
        }
        if ((window.location.href.indexOf('notre-savoir-faire') > -1) || (window.location.href.indexOf('our-expertise') > -1)) {
            $('#menu-item-20 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });

            $('#menu-item-285 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });
        }

        if ((window.location.href.indexOf('nous-trouver') > -1) || (window.location.href.indexOf('find-us') > -1)) {
            $('#menu-item-19 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });

            $('#menu-item-284 .sub-menu a').each(function () {

                let hash = $(this).attr('href').split('#');
                $(this).attr('href', '#' + hash[1]);

            });
        }
    }

    startAnimation()
    {

        if ($('.avwp-av').length > 0) {
            $('.avwp-av button.yes').click(function () {
                $('.animation').addClass('animation-activate');
                $('.animation h2').addClass('animation-activate');
                $('.animation p').addClass('animation-activate');
                $('.content').addClass('animation-activate');
            });
        } else {
            $('.animation').addClass('animation-activate');
            $('.animation h2').addClass('animation-activate');
            $('.animation p').addClass('animation-activate');
            $('.content').addClass('animation-activate');
        }

    }

}
