import '@lottiefiles/lottie-player';

export class Archive {
    constructor()
    {
        this.isLoading = false;
        this.initLottieLoader()
        this.init()
    }

    initLottieLoader()
    {
        const player = document.querySelector('lottie-player');
        if (typeof(player) != 'undefined' && player != null) {
            let loaderURL = '/app/themes/akyos-sage/resources/assets/images/loader.json'
            player.load(window.location.protocol + '//' + window.location.host + loaderURL)
        }
    }

    init()
    {
        let section = document.querySelector('section.news-page') // check if we are on the news page else return
        if (typeof(section) == 'undefined' || section == null) { return }

        this.filters = document.querySelectorAll('.filter');
        this.filters.forEach((filter) => { // register event listeners for each filter
            filter.addEventListener('click', () => {
                this.filterPosts(filter);
            });
        })

        this.paginations = ['.page-numbers.next', '.page-numbers.prev'] // register pagination elements
        this.paginations.forEach((pagination) => {
            let paginationElement = document.querySelector(pagination)
            if (typeof(paginationElement) == 'undefined' || paginationElement == null) { return }

            paginationElement.addEventListener('click', (evt) => {
                evt.preventDefault();
                this.refresh(paginationElement.getAttribute('href'))
            })
        })
    }

    setActiveFilter(_filter)
    {
        this.filters.forEach((filter) => {
            filter.classList.remove('active')
        })
        _filter.classList.add('active')
    }

    filterPosts(_filter)
    {
        if (this.isLoading) {
            return }

        this.setActiveFilter(_filter)

        let filter = _filter.getAttribute('data-category');
        let url = new URL(window.location.href);
        url.searchParams.append('cat', filter);
        this.refresh(url)

    }

    refresh(url)
    {
        this.isLoading = true;
        let fullSection = $('section.news-page')
        let postSection = $('section#articles')
        let loader = document.querySelector('#loader')

        postSection.empty() // empty post section
        loader.classList.add('visible') // show loader

        const that = this; // save this instance

        $.ajax({
            url: url,
            success: function (response) {
                loader.classList.remove('visible') // show loader
                fullSection.empty() // empty full section
                fullSection.append($(response).find('section.news-page > *')) // append response to full section
                that.isLoading = false;
                that.init() // reinit events listeners & lottie player
            },
        })
    }

}
